/* Generalized Segment Utilities
-------------------------------------------------------------------------------------------------*/

function isDaySegmentCollision(segment, otherSegments, fillGaps) {
	if (!fillGaps) {
		return true;
	}

	for (var i = 0; i < otherSegments.length; i++) {
		var otherSegment = otherSegments[i];
		if (
			otherSegment.leftCol <= segment.rightCol &&
			otherSegment.rightCol >= segment.leftCol
		) {
			return true;
		}
	}
	return false;
}

function segmentElementEach(segments, callback) {
	// TODO: use in AgendaView?
	for (var i = 0; i < segments.length; i++) {
		var segment = segments[i];
		var element = segment.element;
		if (element) {
			callback(segment, element, i);
		}
	}
}

// A cmp function for determining which segments should appear higher up
function compareDaySegments(a, b) {
	// sort metadata options:
	// value
	//

	// Add custom sort from metadata here
	var sortPriority = getSortValueFromInput(
		a.event.metadata?.sortPriority?.value,
		b.event.metadata?.sortPriority?.value
	);
	var sortOverride = getSortValueFromInput(
		a.event.metadata?.sortOverride?.value,
		b.event.metadata?.sortOverride?.value
	);

	return (
		sortOverride ||
		b.rightCol - b.leftCol - (a.rightCol - a.leftCol) || // put wider events first
		b.event.allDay - a.event.allDay || // if tie, put all-day events first (booleans cast to 0/1)
		(!b.event.allDay && !a.event.allDay && a.event.start - b.event.start) || //|| // if a tie, sort by event start date - Customized by seedcode so we don't sort by time if all day
		sortPriority
	);
}
// A cmp function for determining which segments should appear higher up
function compareClusterEnabledSegments(a, b) {
	// sort metadata options:
	// value
	//

	// Add custom sort from metadata here
	var sortPriority = getSortValueFromInput(
		a.event.metadata?.sortPriority?.value,
		b.event.metadata?.sortPriority?.value
	);
	var sortOverride = getSortValueFromInput(
		a.event.metadata?.sortOverride?.value,
		b.event.metadata?.sortOverride?.value
	);

	return (
		sortOverride ||
		a.event.start - b.event.start || // put events that start first at the top
		b.rightCol - b.leftCol - (a.rightCol - a.leftCol) || // then wider events
		b.event.allDay - a.event.allDay || // then all day
		sortPriority
	);
}

function getSortValueFromInput(aSort, bSort) {
	let result = 0;
	if (
		(aSort === undefined || aSort === null) &&
		(bSort === undefined || bSort === null)
	) {
		// If both sides of the comparison are unset then don't change sort
		result = 0;
	} else if (aSort === undefined || aSort === null) {
		// If the fist comparison is unset then move the item back in the list
		result = 1;
	} else if (bSort === undefined || bSort === null) {
		// If the second comparison is unset then move the item forward in the list
		result = -1;
	} else if (isNaN(aSort) || isNaN(bSort)) {
		// Both sides of the compare are strings so run local compare
		result = aSort.localeCompare(bSort);
	} else {
		// Both side of the compare are numbers so get the difference
		result = aSort - bSort;
	}
	return result;
}

function getClientSegments(daySegments, slotSegments) {
	var clientSegments = [];
	var i;
	if (daySegments) {
		for (i = 0; i < daySegments.length; i++) {
			clientSegments.push(daySegments[i]);
		}
	}

	if (slotSegments) {
		for (i = 0; i < slotSegments.length; i++) {
			clientSegments.push(slotSegments[i]);
		}
	}

	clientSegments.sort(function (a, b) {
		return a.row - b.row || a.left - b.left || a.top - b.top;
	});

	return clientSegments;
}
